<template>
  <div id="addRedeemCode">
    <!-- 表单 -->
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="110px"
      label-position="left"
    >
      <el-form-item label="兑换码名称" prop="name">
        <el-input
          v-model="ruleForm.name"
          class="w618"
          placeholder="请输入兑换码名称"
          maxlength="12"
          :show-word-limit="true"
        ></el-input>
      </el-form-item>
      <el-form-item label="兑换码数量" prop="stock">
        <el-input
          v-model="ruleForm.stock"
          class="w618"
          placeholder="请输入兑换码数量，上限999"
          maxlength="3"
          @input="ruleForm.stock = ruleForm.stock.replace(/[^\d]/g, '')"
        >
          <template slot="append">上限999</template>
        </el-input>
      </el-form-item>
      <el-form-item label="兑换码有效期" prop="validity">
        <el-date-picker
          :picker-options="pickeroptions"
          value-format="yyyy-MM-dd"
          v-model="ruleForm.validity"
          style="width: 618px"
          type="date"
          placeholder="请选择兑换码截止有效日期"
        ></el-date-picker>
        <p style="font-size: 12px; color: rgba(255, 53, 53, 1)">
          提示：系统默认当天23:59过期
        </p>
      </el-form-item>
    </el-form>
    <!-- 兑换课程 -->
    <div class="exchange">
      <div class="title">
        <span>兑换内容（{{ ruleForm.courseInfo.length }}）</span>
        学生凭兑换码兑换内容，包含已上架和已下架的内容，可按需求选择一或多个
      </div>
      <div class="add_button" @click="networkschoolSelectStatus = true">
        + 选择内容
      </div>
      <div class="classBox" v-if="ruleForm.courseInfo.length > 0">
        <div class="classInfo" v-for="(v, i) in ruleForm.courseInfo" :key="i">
          <img :src="v.photo || v.cover_img" alt="" />
          <ul>
            <li class="hidetext">{{ v.name }}</li>
            <li class="hidetext" v-if="v.course_type == 1">直播课</li>
            <li class="hidetext" v-else-if="v.course_type == 2">小班课</li>
            <li class="hidetext" v-else-if="v.course_type == 3">录播课</li>
            <li class="hidetext" v-else-if="v.course_type == 4">系列课</li>
            <li class="hidetext" v-else-if="v.graphic_id">图文</li>
          </ul>
          <div class="delete el-icon-close" @click="deleteInfo(i)"></div>
        </div>
      </div>
      <div class="noClass" v-else>
        <empty :isOld="false" :positionCenter="true" />
      </div>
      <div class="sub_btn">
        <el-button class="w120" type="primary" @click="submitForm('ruleForm')">
          保存
        </el-button>
      </div>
    </div>
    <!-- 关联课程弹窗 -->
    <!-- <curriculum-select
      v-if="dialogVisible"
      :curriculumSelectIsShow.sync="dialogVisible"
      :selectList="ruleForm.courseInfo"
      @changeSelectList="changeSelectList"
    /> -->

    <!-- 选择商品 -->
    <networkschoolSelect
      @selectChangenetworkschool="closeGoodsConfirm"
      @singleselectcontentval="singleselectcontentval"
      title="添加商品"
      v-if="networkschoolSelectStatus"
      :dialogstatus.sync="networkschoolSelectStatus"
      :template="15"
      :ids="ids"
      :price_mold="1" 
      :goodList="ruleForm.courseInfo"
      :goodlistType="false" 
    ></networkschoolSelect>
    <!--End-->
  </div>
</template>
<script>
import networkschoolSelect from '@cm/base/networktypeSelect/networkschoolSelect'
import empty from '@/components/Empty'
import tool from '@/assets/js/tool.js'
// import curriculumSelect from '@/components/curriculumSelect/index.vue'
export default {
  name: 'addRedeemCode',
  components: {
    empty,
    // curriculumSelect,
    networkschoolSelect,
  },
  data() {
    return {
      // 选择课程弹窗
      networkschoolSelectStatus: false,
      // 日期选择框禁用当天之前日期
      pickeroptions: {
        disabledDate: time => {
          return time.getTime() < Date.now() - 1 * 24 * 60 * 60 * 1000
        },
      },

      // 表单数据
      ruleForm: {
        name: '',
        stock: '',
        validity: '',
        courseInfo: [],
      },
      rules: {
        name: [
          { required: true, message: '请输入兑换码名称', trigger: 'blur' },
        ],
        stock: [
          { required: true, message: '请输入兑换码数量', trigger: 'blur' },
        ],
        validity: [
          { required: true, message: '请选择日期', trigger: 'change' },
        ],
      },
    }
  },
  mounted() {
    if (this.$route.query.redeem_id) {
      this.getDetail(this.$route.query.redeem_id)
      // 改变顶部标题
      this.$root.$children[0].childPageOptions[
        this.$root.$children[0].childPageOptions.length - 1
      ].name = '编辑兑换码'
    } else {
      this.$root.$children[0].childPageOptions[
        this.$root.$children[0].childPageOptions.length - 1
      ].name = '新建兑换码'
    }
    this.$parent.$parent.childPageOptions[0].prompt = ''
  },

  computed: {
    ids() {
      return this.ruleForm.courseInfo
        .filter(item => item.graphic_id)
        .map(item => item.graphic_id)
    },
  },

  methods: {
    // 关闭选择课程
    closeGoodsConfirm() {
      this.networkschoolSelectStatus = false
    },

    // 改变课程列表
    singleselectcontentval(list) {
      this.ruleForm.courseInfo.push(...list)
      this.networkschoolSelectStatus = false
    },

    // 删除一项选择课程
    deleteInfo(index) {
      this.$confirm('是否取消该内容？', '取消内容', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.ruleForm.courseInfo.splice(index, 1)
      })
    },
    // 提交确认
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.ruleForm.courseInfo.length == 0) {
            this.$message({
              message: '至少选择一个内容',
              type: 'warning',
            })
            return false
          }
          let formData = {},
            url = ''
          if (this.$route.query.redeem_id) {
            formData = {
              ...this.ruleForm,
              redeem_id: this.$route.query.redeem_id,
            }
            url = '/Redeem/editRedeemAction'
          } else {
            formData = {
              ...this.ruleForm,
            }
            url = '/Redeem/createRedeemAction'
          }
          this.$http({
            url,
            data: formData,
            callback: res => {
              if (res.code == 200) {
                this.$router.go(-1)
              }
            },
          })
        }
      })
    },
    // 如果是编辑，获取数据详情
    getDetail(redeem_id) {
      this.$http({
        url: '/Redeem/editRedeemAction?redeem_id=' + redeem_id,
        callback: res => {
          if (res.code == 200) {
            this.ruleForm = res.data
            this.ruleForm.validity = tool.formatTimeStamp(
              this.ruleForm.validity,
              'yyyy-MM-dd'
            )
          }
        },
      })
    },
  },
}
</script>
<style lang="less" scoped>
#addRedeemCode {
  background: rgba(255, 255, 255, 1);
  box-sizing: border-box;
  .el-dialog {
    min-width: 955px;
    overflow: hidden;
    .el-dialog__header {
      padding: 0px;
      height: 48px;
      color: rgba(51, 51, 51, 1);
      background: rgba(245, 245, 245, 1);
    }
    .el-dialog__body {
      padding: 0px;
    }
    .zi1 {
      float: left;
      margin-top: 15px;
      margin-left: 2%;
    }
    .zi2 {
      float: left;
      margin-left: 40%;
      margin-top: 15px;
    }
    .center {
      // background-color: red;
      height: 500px;
      width: 100%;
      .left {
        box-sizing: border-box;
        float: left;
        // background-color: yellow;
        width: calc(50% - 1px);
        height: 100%;
        .el-row {
          // background-color: red;
          padding: 20px;
          height: 16%;
        }
        .bottom {
          padding: 0px 20px;
          height: 85%;
          ul {
            height: 100%;
            overflow-y: auto;
            li {
              height: 38px;
              margin-bottom: 20px;
              .tu1 {
                width: 68px;
                height: 38px;
                background: rgba(110, 110, 110, 1);
                float: left;
                img {
                  width: 68px;
                  height: 38px;
                }
              }
              .zi {
                float: left;
                margin-left: 10px;
                .zi3 {
                  width: 230px;
                  font-size: 12px;
                  color: rgba(51, 51, 51, 1);
                }
                .zi4 {
                  color: rgba(153, 153, 153, 1);
                  font-size: 12px;
                  margin-top: 12px;
                }
              }
              .buttonn {
                float: right;
              }
            }
          }
        }
      }
      .right {
        border-left: 1px solid rgba(236, 236, 236, 1);
        float: right;
        // background-color: green;
        width: 50%;
        height: 100%;
        .top1 {
          padding: 20px 20px 0px 20px;
          height: 87%;
          background-color: #fff;
          overflow-y: auto;
          ul {
            li {
              height: 38px;
              margin-bottom: 20px;
              .tu1 {
                width: 68px;
                height: 38px;
                background: rgba(110, 110, 110, 1);
                float: left;
              }
              .zi {
                float: left;
                margin-left: 10px;
                .zi3 {
                  width: 230px;
                  font-size: 12px;
                  color: rgba(51, 51, 51, 1);
                }
                .zi4 {
                  color: rgba(153, 153, 153, 1);
                  font-size: 12px;
                  margin-top: 12px;
                }
              }
              .buttonn {
                float: right;
              }
            }
          }
          li:last-child {
            margin-bottom: 0px;
          }
        }
        .bottom1 {
          height: 13%;
          border-top: 1px solid rgba(236, 236, 236, 1);
          .el-button {
            margin-top: 10px;
            float: right;
            margin-right: 20px;
          }
        }
      }
    }
  }
  .exchange {
    .title {
      width: 100%;
      height: 40px;
      border-bottom: 1px solid rgba(226, 226, 226, 1);
      line-height: 40px;
      color: rgba(102, 102, 102, 1);
      font-size: 12px;
      span {
        font-size: 16px;
        font-weight: bold;
        color: rgba(51, 51, 51, 1);
      }
    }
    .add_button {
      width: 120px;
      height: 42px;
      line-height: 42px;
      border-radius: 4px;
      border: 1px solid rgba(10, 162, 155, 1);
      cursor: pointer;
      color: rgba(10, 162, 155, 1);
      font-size: 14px;
      text-align: center;
      margin-top: 35px;
      margin-bottom: 25px;
    }
    .classBox {
      width: 100%;
      min-height: 60px;
      padding: 10px 0;
      margin-bottom: 25px;
      .classInfo {
        width: 48%;
        height: 60px;
        display: inline-block;
        margin-right: 1%;
        margin-bottom: 10px;
        transition: all 0.5s;
        img {
          float: left;
          width: 88px;
          height: 60px;
        }
        ul {
          float: left;
          width: calc(100% - 120px - 14px);
          height: 60px;
          li {
            width: 100%;
            height: 30px;
            line-height: 30px;
            text-indent: 12px;
            &:nth-of-type(1) {
              font-size: 14px;
              color: rgba(51, 51, 51, 1);
            }
            &:nth-of-type(2) {
              font-size: 12px;
              color: rgba(153, 153, 153, 1);
            }
          }
        }
        .delete {
          float: left;
          width: 14px;
          height: 14px;
          margin-top: 20px;
          cursor: pointer;
          display: none;
        }
        &:hover {
          background: rgba(153, 153, 153, 0.2);
          .delete {
            display: block;
          }
        }
      }
    }
    .sub_btn {
      width: 100%;
      // display: flex;
      // justify-content: center;
    }
  }
  .noClass {
    width: 100%;
    height: 300px;
  }
}
</style>
